<template>
  <div class="logo_wrapper">
    <img class="logo" alt="SNRG" src="./assets/snrgy_logotype.svg" />
  </div>
  <Main />
</template>

<script>
import Main from "./components/Main.vue";

export default {
  name: "App",
  components: {
    Main,
  },
};
</script>

<style>
.logo_wrapper {
  margin: 0 0 50px 0;
}

.logo {
  display: block;
  margin: 0 auto;
}

#app {
  font-family: "EB Garamond", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #002856;
  margin-top: 60px;
  font-size: 21px;
  line-height: 120%;
}

@media (min-width: 700px) {
  #app {
    line-height: 190%;
  }
}
</style>

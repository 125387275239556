import { createApp } from "vue";
import App from "./App.vue";
import VueYandexMetrika from "vue-v3-yandex-metrika";

const app = createApp(App);

app.use(VueYandexMetrika, {
  id: 86381808,
  env: "production",
  options: {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
  },
});

app.mount("#app");

<template>
  <div class="main_wrapper">
    <div class="wrapper">
      <p>
        Клуб «Синергия» — православное сообщество мужчин, которые стремятся
        к личностному развитию и при этом творчески и с юмором относятся
        к жизни. Члены клуба проявляют инициативу и помогают друг другу, когда
        у них есть такая возможность. В клубе участники находят
        единомышленников.
      </p>
      <p>
        В современном православии распространено мнение, что стремление к успеху
        и заработку является грехом. Мы не разделяем этого мнения. Мы понимаем,
        что создание семьи в современном мире требует значительных финансовых
        ресурсов и если не стремиться к материальному благополучию, здоровая
        православная семья исчезнет. Мы развиваемся, чтобы
        <span class="copy_highlight"
          >не закопать данный Богом талант в землю, но употребить в дело
          (Мф. 25:14-23)</span
        > — в надежде стать опорой и примером для других.
      </p>
      <p>
        Вместе мы обсудим, каким должен быть современный православный мужчина
        и что каждому из нас нужно сделать чтобы приблизиться к этому образу.
        И будем работать над собой, делясь опытом.
      </p>
      <div>
        <div class="li_header">Нашими инструментами станут</div>
        <ul class="li_item_wrapper">
          <li class="li_item li_item--dash">изучение Священного Писания</li>
          <li class="li_item li_item--dash">
            чтение научно-популярной и бизнес-литературы
          </li>
          <li class="li_item li_item--dash">
            совместные тренировки по единоборствам и командным видам спорта
          </li>
          <li class="li_item li_item--dash">семинары приглашённых спикеров</li>
          <li class="li_item li_item--dash">
            деловые и психологические тренинги
          </li>
          <li class="li_item li_item--dash">дискуссии на заданную тему</li>
        </ul>
      </div>

      <p>
        На регулярных встречах участники рассказывают о достигнутых результатах,
        делятся проблемами, обмениваются мнениями, общаются, отдыхают. Если
        устанем, мы будем смотреть и говорить о кино, слушать музыку, ходить
        на выставки, экскурсии, ездить в совместные поездки, слушать лекции
        о науке и культуре.
      </p>
      <p>
        Личностный рост требует много ресурсов, поэтому важна дружественная
        атмосфера. Участники призваны делиться опытом, помогать и соревноваться
        друг с другом.
      </p>
      <!-- <div>
        <div class="li_header">Мы придерживаемся следующих приоритетов:</div>
        <ul class="li_item_wrapper">
          <li class="li_item li_item--olist">0 Бог</li>
          <li class="li_item li_item--olist">1 Деньги</li>
          <li class="li_item li_item--olist">2 Своя семья</li>
        </ul>
      </div> -->

      <p>
        Духовное развитие проходит в церкви с духовником. В клубе же участники
        обсуждают практические аспекты жизни.
      </p>
      <!-- <p>
        Участники определяются с главной задачей на 5 лет. Это может быть
        построение бизнеса, достижение определенной должности в компании. Задача
        должна быть достижима (можно использовать критерий «SMART») и придавать
        энергии. Также участники строят видение жизни на 10, 20, 30, 40 лет.
      </p> -->

      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSeEEFmnsj3f2kGrzQNw-zJWCMRbf1Jb_yVk4cn4YCXzz2Qqsg/viewform?embedded=true"
        width="640"
        height="2597"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
        :onload="handeOnLoadEvent"
        class="iframe_form"
        id="iframe_form"
        >Loading…</iframe
      >
      <!-- <a
        href="https://forms.gle/ePXqBUnRGMBb6cEn6"
        class="btn btn_action"
        target="_blank"
        >Подать заявку</a
      > -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Main",
  data() {
    return {
      onloadCount: 1,
    };
  },
  props: {
    msg: String,
  },
  methods: {
    handeOnLoadEvent() {
      console.log("onload even");
      console.log(this.$metrika.reachGoal("iframe_onload" + this.onloadCount));
      console.log("iframe_onload" + this.onloadCount);

      if (this.onloadCount > 1) {
        this.scrollPageTo("iframe_form");
      }

      this.onloadCount = this.onloadCount + 1;
      console.log("iframe_onload" + this.onloadCount);
    },
    scrollPageTo(navEl) {
      console.log(`#${navEl}`);
      let element = document.querySelector(`#${navEl}`);
      console.log(element);
      element.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped sass>
.iframe_form {
  width: 100%;
}

.copy_highlight {
  color: #876d4b;
}

.li_header {
  margin-bottom: 10px;
}

.li_item.li_item--dash::before {
  content: "—";
  display: inline-block;
  color: #876d4b;
  margin-right: 10px;
  position: absolute;
  left: -29px;
}

.li_item.li_item--dash {
  position: relative;
  margin-left: 30px;
}

.li_item--olist::first-letter {
  color: #876d4b;
  margin-right: 10px;
}

.li_item {
  display: block;
  margin-left: 0px;
  margin-bottom: 17px;
  line-height: 120%;
}

@media (min-width: 700px) {
  .li_item {
    line-height: 100%;
  }
}

.li_item_wrapper {
  margin: 0 0 0 0;
}
@media (min-width: 700px) {
  .main_wrapper {
    margin-left: 40px;
  }
}
.btn {
  display: block;
  border: 0px;
  background: white;
  cursor: pointer;
}

.btn_action {
  font-family: "EB Garamond", serif;
  font-size: 24px;
  font-weight: 500;
  color: #002856;
  display: inline-block;
  padding: 7px 20px;
  background: #f2f2f2;
  text-decoration: none;
}

.btn_action:hover {
  background: #002856;
  color: white;
}

.wrapper {
  max-width: 700px;
  margin: 0 auto 200px auto;
}

p {
  text-align: left;
  margin-bottom: 30px;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
